'use client'

import type { Page } from 'cms-types'
import { Component, type ReactNode, useEffect, useState } from 'react'
import React from 'react'
import { Button } from 'ui'

import { renderBlocks } from '@/cms/helpers'
import { getLocaleFromHostname } from '@/helpers/getLocaleFromHostname'
import { richTextToJSX } from '@/helpers/richTextParser'

class ErrorBoundary extends Component<{ children: ReactNode }, { hasError: boolean }> {
  constructor(props: { children: ReactNode }) {
    super(props)
    this.state = { hasError: false }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  static getDerivedStateFromError(_: Error) {
    return { hasError: true }
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className='px-5 pb-10 pt-6 text-center sm:pt-10 xl:px-10'>
          <h1 className='h3 pb-6 lg:!text-[60px]'>Something went wrong :(</h1>
        </div>
      )
    }

    return this.props.children
  }
}

function ErrorPage({ error, reset }: { error: Error; reset: () => void }) {
  const [page, setPage] = useState<Page | null>(null)

  const locale = getLocaleFromHostname(window.location.hostname)

  useEffect(() => {
    console.error(error)
  }, [error])

  useEffect(() => {
    async function fetchPage() {
      const response = await fetch(`/api/error-page?locale=${locale}`)
      const data = await response.json()
      setPage(data)
    }

    fetchPage()
  }, [locale])

  if (!page) return

  if (page && !page.title) {
    return (
      <div className='px-5 pb-10 pt-6 text-center sm:pt-10 xl:px-10'>
        <h1 className='h3 pb-6 lg:!text-[60px]'>Something went wrong :(</h1>

        <Button onPress={reset} appearance='dark' className='m-auto !rounded-pill'>
          Try again
        </Button>
      </div>
    )
  }

  return (
    <>
      <div className='px-5 pb-10 pt-6 text-center sm:pt-10 xl:px-10'>
        {page.title && <h1 className='h3 pb-6 lg:!text-[60px]'>{page.title}</h1>}

        {page.description && <p className='pb-10'>{richTextToJSX(page.description)}</p>}

        {page.showRetryButton && (
          <Button onPress={reset} appearance='dark' className='m-auto !rounded-pill'>
            {page.retryButtonText || 'Try again'}
          </Button>
        )}
      </div>
      {renderBlocks(page.errorBody, locale)}
    </>
  )
}

export default function ClientErrorPage({ error, reset }: { error: Error; reset: () => void }) {
  return (
    <ErrorBoundary>
      <ErrorPage error={error} reset={reset} />
    </ErrorBoundary>
  )
}
